import React, { useState, useEffect, Suspense } from 'react';
import Iframe from './Iframe';
import WatchNowPopup from './WatchNowPopup';

function App() {
  const [showIframe, setShowIframe] = useState(true);
  const [showPopup, setShowPopup] = useState(false); // State for Popup Visibility

  useEffect(() => {
    setTimeout(() => {
      setShowPopup(true); // Show Popup After 5 Seconds
    }, 5000);
  }, []);

  const handleIframeClick = () => {
    setShowIframe(false);
    setShowPopup(true);
  };

  const handleOkClick = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showIframe && (
        <Suspense fallback={<div>Loading...</div>}>
          <Iframe />
          {showPopup && <WatchNowPopup visible={showPopup} onClose={handleOkClick} />}
        </Suspense>
      )}
    </>
  );
}

export default App;