const WatchNowPopup = ({ visible, onClose }) => {
    return (
      <div
        className={`fixed top-0 left-0 right-0 bottom-0 ${
          visible ? 'flex' : 'hidden'
        } items-center justify-center z-10`}
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      >
        <div className="bg-neutral-100 dark:bg-neutral-900 w-11/12 md:w-1/3 max-w-sm rounded-lg shadow-lg">
          <div className="p-8 space-y-4">
            <h2 className="text-xl leading-relaxed text-neutral-800 dark:text-neutral-200">
            If your video is buffering, try switching to a different 
              <strong className="text-black dark:text-white"> #channel</strong>{' '}
            </h2>
          </div>
          <div className="bg-neutral-200 dark:bg-neutral-800 p-3 rounded-b-lg">
            <button
              className="focus:outline-none w-full flex items-center justify-center"
              onClick={onClose}
            >
              <span className="text-base font-semibold text-neutral-900 dark:text-white">
                Okay, got it!
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default WatchNowPopup;
