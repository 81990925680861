import React, { useEffect, useState } from 'react';

const Iframe = () => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  const updateDimensions = () => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  // Detect landscape or portrait orientation
  const checkOrientation = () => {
    setDimensions({ width: window.innerWidth, height: window.innerHeight });

    if (dimensions.width > dimensions.height) {
      setDimensions({ width: dimensions.width, height: dimensions.height });
    } else {
      setDimensions({ width: dimensions.width, height: Math.ceil(dimensions.width * 0.5625) });
    }
  };

  useEffect(() => {
    window.addEventListener('orientationchange', checkOrientation);
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, [dimensions]);

  return (
    <div className='w-screen h-screen'>
      <iframe
        title="Iframe"
        style={{ width: `100%`, height: `100%` }}
        src="https://lord6ablo-aiwebvvv.hf.space"
        frameBorder={0}
      ></iframe>
    </div>
  );
};

export default Iframe;